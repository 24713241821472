<template>
    <div id="OrderCenter">
        <el-card>
            <!-- 表格主体 -->

            <el-table :data="pageData.rows">
                <el-table-column prop="id" label="编号" width="80" />
                <el-table-column prop="contact" label="订单时间" width="170">
                    <template #default="scope">
                        {{ (scope.row.createdAt) }}
                    </template>
                </el-table-column>
                <el-table-column prop="contact" label="订单号" width="120">
                    <template #default="scope">
                        {{ (scope.row.number) }}
                    </template>
                </el-table-column>

                <el-table-column prop="contact" label="申请时间" width="120">
                    <template #default="scope">
                        {{ (scope.row.updatedAt) }}
                    </template>
                </el-table-column>

                <el-table-column prop="contact" label="问题类型" width="120">
                    <template #default="scope">
                        {{ (scope.row.afterSale.questionType) }}
                    </template>
                </el-table-column>

                <el-table-column prop="contact" label="退款金额" width="100">
                    <template #default="scope">
                        {{ (scope.row.afterSale.amount) }} 元
                    </template>
                </el-table-column>

                <el-table-column prop="contact" label="售后类型" width="120">
                    <template #default="scope">
                        {{ (scope.row.afterSale.method) }}
                    </template>
                </el-table-column>

                <el-table-column prop="contact" label="凭证图片" width="200">
                    <template #default="scope">
                        <div class="imgBox">
                            <div v-for="img in scope.row.afterSale.imgs">
                                <img class="imga" :src="img" alt="">
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="contact" label="详细描述" width="120">
                    <template #default="scope">
                        {{ (scope.row.afterSale.detail) }}
                    </template>
                </el-table-column>
                <el-table-column prop="contact" label="问题文件" width="200">
                    <template #default="scope">
                        {{ (scope.row.afterSale.File_list) }}
                    </template>
                </el-table-column>
                <el-table-column prop="contact" label="联系方式" width="120">
                    <template #default="scope">
                        {{ (scope.row.afterSale.phone) }}
                    </template>
                </el-table-column>

                <el-table-column label="操作" :width="width">
                    <template #default="scope">
                        <el-button @click="handleAfterDone(scope.row)" icon="Printer">处理完成</el-button>

                    </template>
                </el-table-column>
            </el-table>

        </el-card>

    </div>
</template>
    
<script setup >
import { onMounted, onUpdated, reactive, ref, watch } from 'vue'
import Api from '@/network'
import OrderInfoDetail from "./adminComponents/orderInfoDetail"
import { request } from '@/network/request'
import { useRouter } from 'vue-router'
import OrderPrint from "./Order-print"
import { ElMessage, ElMessageBox } from 'element-plus'
import { ElNotification } from 'element-plus'

const initData = ref(JSON.parse(localStorage.getItem('initData')))
const getList = async () => {
    let res = await Api.request({
        url: '/order/afterSale',
        method: 'post',
        data: {
            method: 'getAfterSale'
        }
    })
    return res
}
let pageData = ref([])
onMounted(async () => {
    let res = await getList()
    res.data.rows = res.data.rows.map(i => {
        i.afterSale = JSON.parse(i.afterSale)
        return i
    })
    pageData.value = res.data
})
const handleAfterDone = async (order) => {
    let res = await Api.request({
        url: '/order/afterSale',
        method: 'post',
        data: {
            method: 'handleAfterDone',
            number: order.number
        }
    })
    let res2 = await getList()
    res2.data.rows = res2.data.rows.map(i => {
        i.afterSale = JSON.parse(i.afterSale)
        return i
    })
    pageData.value = res2.data
    ElNotification({ title: 'Success', message: "处理完成： " + order.number, type: 'success', })
    return res
}


</script>
    
    
  
<style lang="less">
.imgBox {
    display: flex;
}

.imga {
    width: 50px;
    height: 80px;
    margin-left: 15px;
    border-radius: 8px;
}
</style>
    